import Amplify from "@aws-amplify/core";
import { DevEnvironment, ProdEnvironment, toAmplifyConfig } from "../common";

function isProd(): boolean {
  const { NODE_ENV, REACT_APP_STAGE } = process.env;
  function containsProd(value: string) {
    return value.indexOf("prod") !== -1;
  }
  console.info("REACT_APP_STAGE = ", REACT_APP_STAGE);
  if (!!REACT_APP_STAGE) return containsProd(REACT_APP_STAGE);
  console.info("NODE_ENV = ", NODE_ENV);
  return containsProd(NODE_ENV);
}

export function getConfiguration() {
  const config = isProd() ? ProdEnvironment : DevEnvironment;
  console.info("AmplifyConfig =", config);
  return config;
}

export default function configure() {
  const config = getConfiguration();
  const amplifyConfig = toAmplifyConfig(config);
  Amplify.configure(amplifyConfig);
}
