import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import configureAmplify from "./amplify";
import "./index.css";

configureAmplify();

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Routes = React.lazy(() => import("./pages/Routes"));

function App() {
  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Routes />
      </React.Suspense>
    </Router>
  );
}

export default App;
