import { UserID } from "votesha-model";

export enum AuthActionTypes {
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_LOGOUT = "AUTH_LOGOUT",
}

export interface User {
  readonly admin: boolean;
  readonly email?: string;
  readonly emailVerified?: boolean;
  readonly phone?: string;
  readonly phoneVerified?: boolean;
  readonly username?: string;
  readonly sub?: string;
  readonly userID?: UserID;
}

export interface AuthState {
  readonly loggedIn: boolean;
  readonly loading: boolean;
  readonly currentUser?: User;
}
