import { Auth } from "aws-amplify";

export const getOptions = async (opts = {}) => {
  const session = await Auth.currentSession();
  // @ts-ignore
  const jwtToken = session.idToken.jwtToken;
  return Object.assign(
    {
      headers: {
        Authorization: jwtToken,
      },
    },
    opts
  );
};

export function getPayload<T>(response: { error?: string; payload: T }): T {
  if (!response) {
    throw Error("No response returned from backend");
  }
  if (response.error) {
    throw Error(response.error);
  }
  return response.payload;
}
