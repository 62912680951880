import { Reducer } from "redux";
import { AuthState, AuthActionTypes } from "./types";
import { ActionType } from "typesafe-actions";
import * as authActions from "./actions";

type AuthAction = ActionType<typeof authActions>;

export const initialState: AuthState = {
  loading: true,
  loggedIn: false,
  currentUser: undefined,
};

class ReducerUtils {
  static authLogin(
    action: ActionType<typeof authActions.authLogin>
  ): AuthState {
    return {
      loading: false,
      loggedIn: true,
      currentUser: action.payload,
    };
  }
}

const reducer: Reducer<AuthState, AuthAction> = (
  state = initialState,
  action: AuthAction
) => {
  // console.info(action);
  switch (action.type) {
    case AuthActionTypes.AUTH_LOGIN:
      return ReducerUtils.authLogin(action);
    case AuthActionTypes.AUTH_LOGOUT:
      return {
        ...state,
        loading: false,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default reducer;
