import { createStore, combineReducers, Reducer } from "redux";

import AuthReducer from "./pages/auth/redux/reducer";
import { AuthState } from "./pages/auth/redux/types";

export type { AuthState };

export interface AppState {
  auth: AuthState;
}

const rootReducer: Reducer<AppState> = combineReducers<AppState>({
  auth: AuthReducer,
});

const store = createStore(rootReducer);
export default store;
